/* background-image: image-set(...) works only in Firefox */

@font-face {
  font-family: 'Univers';
  src: url(../src/assets/fonts/linotypeunivers-520cnmedium.ttf) format("truetype"),
    url(../src/assets/fonts/linotypeunivers-520cnmedium.ttf) format("woff2");
}

@font-face {
  font-family: 'Oswald';
  src: url(../src/assets/fonts/Oswald-VariableFont_wght.ttf) format("truetype"),
    url(../src/assets/fonts/Oswald-VariableFont_wght.ttf) format("woff2");
}

:root {
  --bg-color-alpha: #0007;  
  --bg-color: #222;  
  --bg-color-substrate: #2223;
  --bg-highlight-alpha: #3182ce55;
  --bg-highlight: #3182ce;
  --color: #ddd;
  --color-inverted: #222;
  --color-tarnished: #dddc;
  --color-substrate: #ddd3;
  --highlight-alpha: #fbb30a77;
  --highlight: #fbb10a;
  --highlight-hover: #dc9a00;
  --highlight-active: #f2a900;
  --blur5: blur(5px); 
  --blur10: blur(10px); 
  --blur25: blur(25px); 
}

:root[data-theme=dark] {
  --bg-img: url("./assets/bg-desktop-dark.webp");
  --bg-img-set: image-set(
    url("./assets/bg-desktop-dark.webp") type("image/webp"),
    url("./assets/bg-desktop-dark.jpg") type("image/jpeg"));
}

:root[data-theme=light] {
  --bg-img: linear-gradient(#fffa, #fff7), url("./assets/bg-desktop-light.webp");
  --bg-img-set: linear-gradient(#fffa, #fff7), image-set(    
    url("./assets/bg-desktop-light.webp") type("image/webp"),
    url("./assets/bg-desktop-light.jpg") type("image/jpeg"));

  --bg-color-alpha: #fff7; 
  --bg-color: #ddd;
  --bg-color-substrate: #ddd7;
  --bg-highlight: #fbb10a;
  --bg-highlight-alpha: #fbb30a77;
  --color: #222;
  --color-inverted: #ddd;
  --color-tarnished: #222c;
  --color-substrate: #2223;
  --highlight-alpha: #3182ce77;  
  --highlight: #3182ce;  
  --highlight-hover: #0084ff;
  --highlight-active: #005eb6;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Univers';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  background-color: var(--bg-color);
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-image: var(--bg-img-set); */
  background-image: var(--bg-img);
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#nojs {
  background-image: url("./assets/bg-desktop-dark.webp");
  position: fixed; 
  color: #ddd; 
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: grid;
  justify-content: center;
  align-items: center;
  inset: 0;
}

#nojs_text {
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 50px;
  text-transform: uppercase;
  background-image: linear-gradient(rgba(0, 0, 0, 0.753), rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.753));
  backdrop-filter: blur(5px);
}