$width_100vw: 100vw;
$margin: 10px;
$height: 130px;
$breakpoint_tablet: 900px;

.server {
  max-width: $width_100vw;
  height: $height;
  margin: $margin;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #0003;
  transition: all .25s ease-in-out;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, var(--bg-color), var(--bg-color)) !important;
    box-shadow: 4px 4px 5px #0003;
    transform: translate(-2px, -2px);
    transition: all .25s ease-in-out;
    
    .image_svg_container {
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint_tablet) {
  .server {
    height: 160px;
  }
}

@media (max-width: 650px) {
  .server {
    min-height: 160px;
    height: fit-content;
  }
}

@media (min-width: 651px) {
  .server {
    background: linear-gradient(90deg, var(--bg-color), var(--bg-color-alpha)) !important;
  }
}

.image_container {
  min-width: calc(2.25*$height);
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media (max-width: $breakpoint_tablet) {
  .image_container {
    min-width: 150px;
  }
}

@media (max-width: 650px) {
  .image_container {
    display: none;
  }
}

.image_svg_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(45deg, #0006, #0003);
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.image_play { 
  width: 48px;
  height: 48px;
  fill: #fff;
  margin: 0 auto;
}

.data_container {
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  max-width: calc($width_100vw - 4*$margin);
  margin-bottom: 5px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 16px;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  color: var(--color-tarnished);
}

@media (max-width: $breakpoint_tablet) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 12px;
  }
}

.info {
  background-color: var(--bg-color-alpha);
  border-radius: 10px;
  padding: 5px;
  margin: 2px;
  white-space: nowrap;
}

.image_nexus_mod {
  height: 12px;
  width: 12px;
  margin-left: 2px;
  margin-bottom: -2px;
}

.image_proxy_flag {
  width: 10px;
  height: 10px;
  border: 2px solid var(--color);
  border-radius: 25%;
  margin-left: 2px;
  margin-bottom: -2px;
}