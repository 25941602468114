@import '../../styles/common.scss';

.status {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  min-height: $header_height;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-highlight);
  backdrop-filter: var(--blur10);
}

.text {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .text {   
    white-space: nowrap;
    overflow-x: scroll;
  }
}


.shown {
  animation: slide-open .5s ease-in-out;
}
// TODO: bug for mobile
.closed {
  //opacity: 0;
  animation: slide-close .5s ease-in-out;
  top: -$header_height;
}

// TODO: bug for mobile
@keyframes slide-open {
  0% {
      opacity: 0;
      transform: translate(0, -$header_height);
  }

  100% {
      opacity: 1;
      transform: translate(0, 0);
  }
}

@keyframes slide-close {
  0% {
      opacity: 1;
      transform: translate(0, $header_height);
  }

  100% {
      opacity: 0;
      transform: translate(0, -$header_height);
  }
}

.image {
  display: none;
}

.info {
  cursor: help;
  padding: 0 15px;
}

.close {
  cursor: pointer;
  padding: 0 15px;
}

